<template>
  <div>
    <lets-pay ref="elLetsPay"></lets-pay>
  </div>
</template>
<script>
import letsPay from "../../admin-module/liquidations/Letspay.vue";
import { AdminService } from "../../admin-module/AdminService";
import { GeneralService } from "../../general-module/GeneralService";

export default {
  components: {
    letsPay
  },

  // directives
  // filters

  props: {
    employeeLiquidationId: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    employeeLiquidationId: {
      immediate: true,
      handler(to) {
        if (to) {
          this.getLiquidation();
        }
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getLiquidation() {
      GeneralService.getEmployeeLiquidation(this.employeeLiquidationId).then(
        res => {
          AdminService.getLiquidation(res.liquidation_id).then(reg => {
            this.$refs.elLetsPay.load(reg);
          });
        }
      );
    }
  }
};
</script>

<style></style>
