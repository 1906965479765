<template>
  <div>
    <table class="table table-bordered">
      <tr>
        <td>
          Porcentaje de ganancia en esta categoría de prestaciones (productos):
        </td>
        <td class="text-right">
          {{ reg.portion_by_service | percent }}
        </td>
      </tr>
      <tr>
        <td>Precio prestación:</td>
        <td>
          <app-span-money
            :quantity="reg.unit_price"
            :moneyCode="1"
          ></app-span-money>
        </td>
      </tr>
      <tr v-show="reg.cost">
        <td>Costo de laboratorio</td>
        <td>
          <app-span-money :quantity="-reg.cost" :moneyCode="1"></app-span-money>
        </td>
      </tr>
      <tr v-show="reg.cost">
        <td>Precio prestacion (sin laboratorio)</td>
        <td>
          <app-span-money
            :quantity="reg.unit_price - reg.cost"
            :moneyCode="1"
          ></app-span-money>
        </td>
      </tr>
      <tr>
        <td>Subtotal a pagar:</td>
        <td>
          <app-span-money
            :quantity="reg.amount"
            :moneyCode="1"
          ></app-span-money>
        </td>
      </tr>
    </table>

    <!-- <pre>{{ reg }} </pre> -->
  </div>
</template>
<script>
// import { DentalClinicService } from "../service";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    reg: {}
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(employee_payment) {
      this.reg = employee_payment;
    }
  }
};
</script>

<style></style>
