<template>
  <div>
    <h4>{{ employee && employee.name }}</h4>
    <table class="table table-sm">
      <tr>
        <th></th>
        <th>CLIENTE</th>
        <th>TRATAMIENTO</th>
        <th>FECHA</th>
        <th>PIEZA</th>
        <th>MONTO</th>
      </tr>
      <tbody>
        <tr v-for="l in details" :key="l.id">
          <td class="btn-group">
            <a
              href="#"
              class="btn btn-sm btn-light"
              @click.prevent="
                $refs.dExpl.show();
                $refs.explain.load(l);
              "
            >
              <i class="fa fa-search"></i> Explicar
            </a>
            <a
              href="javascript:void(0);"
              class="btn btn-sm btn-light"
              @click="
                $refs.elModChaPer.show();
                $refs.elChaPer.load(l);
              "
            >
              <i class="fas fa-percentage"></i>
            </a>
          </td>
          <td>{{ l.client_name }}</td>
          <td :title="l.treatment_plan_detail_id">{{ l.product_name }}</td>
          <td>{{ l.maked_date }}</td>
          <td>
            <ToothPiece
              v-for="l1 in l.pieces"
              :key="l1"
              :piece="l1"
            ></ToothPiece>
          </td>
          <td class="text-right" :title="l.id">
            <app-span-input
              :value="l.amount"
              @input="setCustomAmount(l, $event)"
              :sm="true"
            >
              <app-span-money
                :quantity="l.amount"
                :moneyCode="l.money_code"
              ></app-span-money>
            </app-span-input>
          </td>
          <td>
            <PayState :detail="l"></PayState>
          </td>
        </tr>
        <tr>
          <th class="text-right" colspan="5">TOTAL</th>
          <th class="text-right">
            <app-span-money :quantity="total" :moneyCode="1"></app-span-money>
          </th>
        </tr>
      </tbody>
    </table>

    <div class="row mt-3">
      <div class="col">
        <payDetailsContainer
          :employeeLiquidationId="$route.query.employee_liquidation_id"
          v-show="$route.query.employee_liquidation_id"
        ></payDetailsContainer>
      </div>
      <div class="col"></div>
    </div>

    <app-modal-basic ref="dExpl">
      <h4>Explicacion</h4>
      <pay-explain ref="explain"></pay-explain>
    </app-modal-basic>

    <app-modal-basic ref="elModChaPer">
      <ChangePercentage
        ref="elChaPer"
        @submitted="
          $refs.elModChaPer.hide();
          getPayments();
        "
      ></ChangePercentage>
    </app-modal-basic>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import PayState from "../treatments/PayState";
import payExplain from "./pay-explain";
import ToothPiece from "../odontogram/ToothPiece";
import payDetailsContainer from "./payDetailsContainer.vue";
import ChangePercentage from "./ChangePercentage.vue";
import { AdminService } from "../../admin-module";

export default {
  components: {
    PayState,
    payExplain,
    ToothPiece,
    payDetailsContainer,
    ChangePercentage
  },

  // directives
  // filters

  props: {
    employee_id: {}
  },

  data: () => ({
    details: [],
    time: null,
    employee: null
    // employee_id: null
  }),

  computed: {
    total() {
      let total = 0;
      this.details.map((x) => {
        total += parseFloat(x.amount);
      });
      return total;
    }
  },

  watch: {
    employee_id: {
      handler() {
        this.getPayments();
      }
    }
  },

  mounted() {
    // this.$refs.selTime.setDefault();
    this.getPayments();
  },

  methods: {
    async setCustomAmount(row, customAmount) {
      row.amount = customAmount;
      try {
       await DentalClinicService.saveEmployeePayment({
        change_amount:1,
        ...row
      }); 
      } catch (error) {
        window.alert("No se logro cambiar el monto, " + error.response.data.message);
      }
      
    },
    changeToPayState(id, toPay) {
      DentalClinicService.setEmployeePaymentToPay(id, toPay);
    },
    // setEmployeeId(employeeId) {
    //   this.employee_id = employeeId;
    //   this.$refs.selTime.setDefault();
    //   // this.getPayments();
    // },
    async getPayments() {
      DentalClinicService.getEmployeePayments({
        employee_id: this.employee_id,
        // ...this.time
        ...this.$route.query
      }).then((res) => {
        this.details = res;
      });

      this.employee = await AdminService.getEmployee(this.employee_id);
    }
  }
};
</script>

<style></style>
