<template>
  <span>
    <i
      v-show="detail.financied"
      title="Financiado"
      class="text-info fa fa-lock"
    ></i>
    <span v-show="!detail.financied">
      <i
        v-show="detail.paid_out"
        title="Pagado"
        class="text-success fa fa-circle"
      ></i>
      <i
        v-show="!detail.paid_out && !detail.maked"
        title="No Pagado"
        class="fa fa-circle"
      ></i>
      <i
        v-show="detail.maked && !detail.paid_out"
        title="debe este causa"
        class="fa fa-circle"
        :class="{
          app_no_alcanza: detail.treatment_plan_payment > 0,
          'text-danger': detail.treatment_plan_payment <= 0
        }"
      ></i>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    detail: {}
  }
};
</script>

<style>
.app_no_alcanza {
  color: yellow;
}
</style>
