<template>
  <div>
    <form @submit.prevent="save()">
      <div class="form-group">
        <label>Porcentage en favor del doctor</label>
        <input
          type="range"
          class="form-control-range"
          v-model="portion"
          min="0"
          max="1"
          step="0.01"
        />
        <span>{{ portion | percent }}</span>
      </div>
      <app-checkbox v-model="lab_job_discounts">
        Descontar trabajos de laboratorio
      </app-checkbox>
      <app-button-submit></app-button-submit>
    </form>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    portion: 0,
    detail: {},
    lab_job_discounts: true
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(detail) {
      this.detail = detail;
      this.portion = detail.portion;
      this.lab_job_discounts = true;
    },
    save() {
      DentalClinicService.saveEmployeeLiquidation({
        id: this.detail.id,
        portion: this.portion,
        update_portion: 1,
        lab_job_discounts: this.lab_job_discounts
      }).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style scoped></style>
